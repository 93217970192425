 import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/auth.service';
import { Credit } from 'app/components/models/credit.model';
import { HttpService } from 'app/http.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

    clé: any;
    credits$!: Credit;
    bool: boolean = false;


    constructor(private httpClient: HttpClient,
        public http: HttpClient,
        public httpService: HttpService,
        public authService: AuthService) { }

    ngOnInit() {}

    login(){
        this.authService.login();
        this.clé = this.authService.getToken();
        console.log(this.clé, "<<< token societeinfo ");
    }

    credit(){
    //    console.log("Nombre de crédit : ", this.httpService.getCreditNumber(this.clé)); 
    //    this.credits$ = this.httpService.getCreditNumber(this.clé);
       this.httpService.getCreditNumber(this.clé).subscribe({
        next: (v) => (this.credits$ = v, console.log(this.credits$.success), this.bool = this.credits$.success),
        // next: (v) => console.log(v.success),
        error: (e) => console.error(e),
        complete: () => console.info('complete')
      });

    }

    
}
