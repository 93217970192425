 import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
    test : Date = new Date();
    focus;
    focus1;
  
    constructor(private httpClient: HttpClient,
        public http: HttpClient,) { }

    ngOnInit(): void {
    }

    onSubmitForm(form: NgForm) {
        // console.log(form.value);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
          }
        if (form.valid) {
            this.http
              .post("https://formspree.io/f/xyyozook", form.value, httpOptions).subscribe(results => {
                // console.log(results);
                alert("Merci, votre message a bien été envoyé :)")
                window.location.reload();
              });
          }
    }

    scrollToElement($element): void {
        // console.log($element);
        $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
      }

    
}
