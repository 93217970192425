import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// import { LandingComponent } from './landing/landing.component';
// import { ProfileComponent } from './profile/profile.component';
// import { SignupComponent } from './contact/contact.component';
// import { ContactComponent } from './contact/contact.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
    ],
    declarations: [
        // LandingComponent,
        // SignupComponent,
        // ProfileComponent
        // ContactComponent,
    ]
})
export class ExamplesModule { }
