import { Component, OnInit } from '@angular/core';


@Component({
    selector: 'app-sectionanim',
    templateUrl: './sectionanim.component.html',
    styleUrls: ['./sectionanim.component.scss']
})
export class SectionAnimComponent implements OnInit {
    public change: boolean = false;
    public mobile: boolean = false;
    public formulaire: boolean = false;

    constructor() {}
  
    ngOnInit() {

        if (window.screen.width <= 1080) {
            // action sur scroll désactivé sur mobile et tablette
            this.mobile = true;
            this.change = true
          };
  
    }

    displayForm() {
        console.log("button work");
        this.formulaire = true

    }

}
