import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { NouisliderModule } from 'ng2-nouislider';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { RouterModule } from '@angular/router';

import { NavigationComponent } from './navigation/navigation.component';
import { TypographyComponent } from './typography/typography.component';
import { ComponentsComponent } from './components.component';
import { NotificationComponent } from './notification/notification.component';
import { NgbdModalComponent } from './modal/modal.component';
import { NgbdModalContent } from './modal/modal.component';
import { SectionAnimComponent } from './sectionanim/sectionanim.component';
import { DevisComponent } from 'app/examples/devis/devis.component';
import { OffreComponent } from 'app/examples/offre/offre.component';
import { RealisationComponent } from 'app/examples/realisation/realisation.component';
import { ContactComponent } from 'app/examples/contact/contact.component';
import { beachAnimComponent } from 'app/examples/beachAnim/beachanim.component';
import { ProfileComponent } from 'app/examples/profile/profile.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        NouisliderModule,
        RouterModule,
        JwBootstrapSwitchNg2Module
    ],
    declarations: [
        ComponentsComponent,
        NavigationComponent,
        TypographyComponent,
        NotificationComponent,
        SectionAnimComponent,
        NgbdModalComponent,
        NgbdModalContent,
        DevisComponent,
        RealisationComponent,
        OffreComponent,
        ContactComponent,
        beachAnimComponent,
        ProfileComponent
    ],
    entryComponents: [NgbdModalContent],
    exports:[ ComponentsComponent ]
})
export class ComponentsModule { }
