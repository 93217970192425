import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { projetResponse } from './projet-response';
import { Observable } from 'rxjs';
import { Projet } from './components/models/projet.model';
import { Credit } from './components/models/credit.model';



@Injectable({
  providedIn: 'root'
})
export class HttpService {


  constructor(private http: HttpClient) { }

  headerDict = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Headers': 'Content-Type',
  }

  requestOptions = {
    headers: new Headers(this.headerDict),
  };

  getProjet(callback) {
    this.http.get<projetResponse[]>('https://jepromenevotrechien.com/dbobj.php')
      .subscribe(projetResponse => {
        console.log("getProjet works >>", projetResponse[0]);
        //  const values = Object.values(projetResponse.projetResponse);
        // console.log("getProjet works >>", values)

        callback(projetResponse);
      });
  }

  getAllProjet(): Observable<Projet[]> {
    return this.http.get<Projet[]>('https://charlygo.fr/dbjson.php')
  }

  getProjetById(projetId: number): Observable<Projet> {
    return this.http.get<Projet>(`https://jepromenevotrechien.com/dbobj.php/${projetId}`);
  }


  // getConnectWithGGl () {
  //   console.log("getConnectWithGGl");
  //   return this.http.get(`https://mybusinessaccountmanagement.googleapis.com/v1/accounts`);

  //   // return this.http.post(`https://oauth2.googleapis.com`, {client_id=your_client_id&client_secret=your_client_secret&refresh_token=refresh_token&grant_type=refresh_token }).pipe(
  //     // return this.http.post(`https://oauth2.googleapis.com`, { data : 'client_id=68864263633-m3pdb5kk6tk9vrjp3716hhb4bc0abh77.apps.googleusercontent.com&client_secret=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&refresh_token=refresh_token&grant_type=refresh_token' }).pipe(
  //       return this.http.post(`https://oauth2.googleapis.com`, { data : 'client_id=68864263633-m3pdb5kk6tk9vrjp3716hhb4bc0abh77.apps.googleusercontent.com&client_secret=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&refresh_token=refresh_token&grant_type=refresh_token', this:this.requestOptions }).pipe(
  //     map((res: any) => {
  //       return res['data'];
  //     })
  //   );
  // }

  getCreditNumber(key: any) {
    // GET https://something X-API-KEY:YOUR_APIKEY
    // GET https://societeinfo.com/app/rest/api/v2/apikeyinfo.json?key=YOUR_APIKEY
    return this.http.get<Credit>(`https://societeinfo.com/app/rest/api/v2/apikeyinfo.json?key=${key}`);
    // return this.http.get(`https://societeinfo.com/app/rest/api/v2/apikeyinfo.json?key=${key}`);
  }
}

