import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-beachanim',
    templateUrl: './beachanim.component.html',
    styleUrls: ['./beachanim.component.scss']
})

export class beachAnimComponent implements OnInit {
  public mobile: boolean = false;
  public change: boolean = false;

  constructor() { }

  ngOnInit() {
    if (window.screen.width <= 860) {
      this.mobile = true;
    };
    setTimeout(this.playAudio.bind(this), 5000);
  }

  playAudio(){
    this.change = true;
  }

}
