import { Component, OnInit } from '@angular/core';
import { HttpService } from 'app/http.service';
import { Observable } from 'rxjs';
import { Projet } from '../../components/models/projet.model'


@Component({
    selector: 'app-realisation',
    templateUrl: './realisation.component.html',
    styleUrls: ['./realisation.component.scss']
})

export class RealisationComponent implements OnInit {
  focus: any;
  focus1: any;
  projetrecup:any;
  mobile: boolean;

  projets$!: Observable<Projet[]>;

  constructor(
    public httpService: HttpService,
  ) { }

  ngOnInit(): void {
    this.projets$ = this.httpService.getAllProjet();
    // console.log(this.projets$);
    //     console.log(Projet[0]);
      // this.httpService.getProjet(this.projetrecup);
      if (window.screen.width <= 500) {
        this.mobile = true;
      };
  }

  scrollToElement($element): void {
    $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }


}
