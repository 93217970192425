import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private token!: string;

  login() {
    localStorage.clear;
    this.token = '57gcgi3i5630vp2i8vifeplonehrt58fkkoo59ukghhldtatr60';
    localStorage.setItem('tok', this.token );
  }

  getToken(): string {
    // return this.token;
    return localStorage.getItem('tok');
  }

}
