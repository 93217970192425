import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, ActivatedRoute, RouterModule } from '@angular/router';

import { ComponentsComponent } from './components/components.component';
import { RealisationComponent } from './examples/realisation/realisation.component';
import { OffreComponent } from './examples/offre/offre.component';
import { DevisComponent } from './examples/devis/devis.component';
import { ContactComponent } from './examples/contact/contact.component';
import { beachAnimComponent } from './examples/beachAnim/beachanim.component';
import { ProfileComponent } from './examples/profile/profile.component';
// import { SignupComponent } from './examples/contact/contact.component';

const routes: Routes =[
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home',             component: ComponentsComponent },
    { path: 'devis',     component: DevisComponent },
    { path: 'contact',           component: ContactComponent },
    { path: 'offre',          component: OffreComponent },
    // { path: 'signup',          component: SignupComponent },
    { path: 'realisation',          component: RealisationComponent },
    { path: 'beach',          component: beachAnimComponent },
    { path: 'profile',   component: ProfileComponent},
    { path: 'website',             component: ComponentsComponent },
    { path: 'organization',             component: ContactComponent },
    { path: 'primaryimage',             component: ComponentsComponent },
    { path: 'breadcrumb',             component: ComponentsComponent },
    { path: 'schema/logo/image/',             component: ComponentsComponent },

    { path: '**',       redirectTo: 'home', pathMatch: 'full'  }, 
    // { path: '**',             component: ComponentsComponent }, 
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      useHash: true,
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
