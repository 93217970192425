import { Component, OnInit, Renderer2 } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-components',
  templateUrl: './components.component.html',
  styles: [`
    ngb-progressbar {
        margin-top: 5rem;
    }
    p { 
      font-family: "Montserrat", "Helvetica", Arial, sans-serif;
      color: #ffbd59 !important;
      background: transparent;
      overflow: hidden;
    }
    
    .item-1, 
    .item-2, 
    .item-3 {
      position: absolute;
      display: block;
      top: 4em;
        margin-left: -220px;
      width: 27%;
      font-size: 2em;
      animation-duration: 20s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
    }
    
    .item-1{
      animation-name: anim-1;
    }
    
    .item-2{
      animation-name: anim-2;
    }
    
    .item-3{
      animation-name: anim-3;
    }
    
    @keyframes anim-1 {
      0%, 8.3% { left: -100%; opacity: 0; }
      8.3%,25% { left: 25%; opacity: 1; }
      33.33%, 100% { left: 110%; opacity: 0; }
    }
    
    @keyframes anim-2 {
      0%, 33.33% { left: -100%; opacity: 0; }
      41.63%, 58.29% { left: 25%; opacity: 1; }
      66.66%, 100% { left: 110%; opacity: 0; }
    }
    
    @keyframes anim-3 {
      0%, 66.66% { left: -100%; opacity: 0; }
      74.96%, 91.62% { left: 25%; opacity: 1; }
      100% { left: 110%; opacity: 0; }
    }
    `]
})

export class ComponentsComponent implements OnInit {
  public mobile: boolean = false;
  page = 4;
  page1 = 5;
  focus;
  focus1;
  focus2;
  date: { year: number, month: number };
  model: NgbDateStruct;
  constructor(private renderer: Renderer2) { }
  isWeekend(date: NgbDateStruct) {
    const d = new Date(date.year, date.month - 1, date.day);
    return d.getDay() === 0 || d.getDay() === 6;
  }

  isDisabled(date: NgbDateStruct, current: { month: number }) {
    return date.month !== current.month;
  }

  ngOnInit() {
    let input_group_focus = document.getElementsByClassName('form-control');
    let input_group = document.getElementsByClassName('input-group');
    for (let i = 0; i < input_group.length; i++) {
      input_group[i].children[0].addEventListener('focus', function () {
        input_group[i].classList.add('input-group-focus');
      });
      input_group[i].children[0].addEventListener('blur', function () {
        input_group[i].classList.remove('input-group-focus');
      });
    }
    if (window.screen.width <= 1079) {
      this.mobile = true;
    };
  }

}
