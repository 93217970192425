import { Component, OnInit, ElementRef } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})


export class NavbarComponent implements OnInit {
    private toggleButton: any;
    private sidebarVisible: boolean;
    public realisation: boolean = false;
    public devis: boolean = false;
    public contact: boolean = false;
    public offre: boolean = false;

    constructor(public location: Location, private element: ElementRef) {
        this.sidebarVisible = false;
    }

    ngOnInit() {
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        this.isDocumentation();
    }
    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const html = document.getElementsByTagName('html')[0];
        // console.log(html);
        // console.log(toggleButton, 'toggle');

        setTimeout(function () {
            toggleButton.classList.add('toggled');
        }, 500);
        html.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const html = document.getElementsByTagName('html')[0];
        // console.log(html);
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        html.classList.remove('nav-open');
    };
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    };
    isHome() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if (titlee.charAt(0) === '#') {
            titlee = titlee.slice(1);
            this.contact = false;
            this.realisation = false;
            this.offre = false;
            this.devis = false;
        }
    }
    isDocumentation() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if (titlee.charAt(0) === '#') {
            titlee = titlee.slice(1);
        }
        // if (titlee === '/creation') {
        //     return true;
        // }
        // else {
        //     return false;
        // }

        switch (titlee) {
            case '/realisation':
                this.realisation = true;
                this.devis = false;
                this.offre = false;
                this.contact = false;
                break;
            case '/devis':
                this.devis = true;
                this.realisation = false;
                this.offre = false;
                this.contact = false;
                break;
            case '/offre':
                this.offre = true;
                this.realisation = false;
                this.devis = false;
                this.contact = false;
                break;
            case '/contact':
                this.contact = true;
                this.realisation = false;
                this.offre = false;
                this.devis = false;
                break;
            case '/home':
                this.contact = false;
                this.realisation = false;
                this.offre = false;
                this.devis = false;
                break;
            default:
                break;
        }


    }
}
