import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-offre',
    templateUrl: './offre.component.html',
    styleUrls: ['./offre.component.scss']
})

export class OffreComponent implements OnInit {
  focus: any;
  focus1: any;

  constructor() { }

  ngOnInit() {}

  scrollToElement($element): void {
    $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }
}
