import { Component } from '@angular/core';

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})

export class NotificationComponent {

    redirection1(){
        window.open("https://map.charlygo.fr/");
    }

    redirection4(){
        window.open("https://chien.charlygo.fr/");
    }

    redirection5(){
        window.open("https://acai.charlygo.fr/");
    }

    redirection7(){
        window.open("https://ecomm.charlygo.fr/");
    }

    redirection9(){
        window.open("https://www.seeonsea.com/");
    }
}
