import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';



@Component({
  selector: 'app-devis',
  templateUrl: './devis.component.html',
  styleUrls: ['./devis.component.scss']
})

export class DevisComponent implements OnInit {
  public mobile: boolean = false;
  
  constructor(private httpClient: HttpClient,
    public http: HttpClient,) { }

  ngOnInit(): void {
    document.documentElement.style.overflow = 'hidden';
    if (window.screen.width <= 1280) {
      this.mobile = true;
    };

  }

  onSubmitForm(form: NgForm) {
    console.log(form.value);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }
    if (form.valid) {
      this.http
        .post("https://formspree.io/f/xyyozook", form.value, httpOptions).subscribe(results => {
          // console.log(results);
          alert("Merci " + form.value.Name + ", votre demande a bien été envoyée ! ");
          window.location.reload();
        }, Error => alert("Attention, votre e-mail comporte une erreur"));
    } else {
      alert("Attention, votre e-mail comporte une erreur")
    }

  }

  // scrollToElement($element): void {
  //   $element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  // }
}

